.student-payment {
  &__custom-fee {
    display: flex;
    justify-content: center;
    align-items: center;

    .form-check-input {
      margin-bottom: 5px;
    }

    .form-check-label {
      align-items: center;
      display: flex;
      .input-field {
        margin-left: 10px;
      }
    }
  }
}
