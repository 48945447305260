.verification {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  &__text {
    text-align: center;
    font-size: 20px;
  }

  &__btn {
    margin-top: 10px;
  }
}
