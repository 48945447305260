.link--with-submenu {
  position: relative;

  svg {
    margin-left: 5px;
    width: 12px;
    height: auto;
    transition: transform .2s ease-in-out;
  }

  .submenu {
    position: absolute;
    // background-color: rgb(51, 51, 51);
    background-color: $light-header-bg;
    color: $white;
    border: 1px solid black;
    border-radius: 2px;
    overflow: hidden;
    z-index: 1000;
    right: 0;
    top: 38px;
    display: none;

    .link {
      padding: 7px 32px;
      display: block;
      text-align: center;
      color: $text;
      &:hover {
        text-decoration: none;
        // background-color: rgb(67, 67, 67);
        background-color: $white;
        color: $header-text;
      }
    }
  }

  &.active {
    .submenu {
      display: block;
    }
    svg {
      transform: rotate(180deg);
    }
  }
}
