progress::-moz-progress-bar { 
  background: $highlight-text; 
}
progress::-webkit-progress-value { 
  background: $highlight-text; 
}
progress {
  color: $highlight-text;
}

#bar {
  width: 100%;
  height: 1rem;
  background-image: $highlight-text;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#bardiv {
  margin-top: 1.5rem;
}

.file-viewer {
    // font-family: Arial, sans-serif;
    // font-family: 'Open Sans', sans-serif;
    color: $text;
    max-width: 600px;
    margin: 0 auto;
    padding-top: 20px;
}

.directory-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.directory-name {
  font-weight: bold;
  cursor: default;
}

.file-tree {
  list-style-type: none;
  padding: 0;
}

.sub-tree {
  list-style-type: none;
  padding-left: 30px;
  position: relative;
  width: 100%;
}

.sub-tree:before {
  content: "";
  position: absolute;
  top: 0;
  left: 12px;
  bottom: 0;
  width: 1px;
  background-color: #ccc;
}

.file-list {
    list-style: none;
    padding: 0;
}

.file-item {
    background-color: #f3f3f3;
    color: $text;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.file-item a {
    text-decoration: none;
    color: $text;
}

.download-button {
    background-color: $highlight-text;
    color: $white;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
}

.download-button:hover {
    background-color: #185abc;
}
