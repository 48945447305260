.applications {
  h1 {
    font-size: 2rem;
  }
  @media (max-width: 550px) {
    h1 {
      font-size: 1.5rem;
    }
  }
}
