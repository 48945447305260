.block {
  border: 1px solid $text;
  position: relative;
  overflow-wrap: break-word;
  margin-top: 20px;

  &__title-wrapper {
    padding: 20px;
    transform: translateY(-50%);
    position: absolute;
    height: auto;
    width: 85%;
    left: 15px;
    margin: 0 auto;
    padding-bottom: -50%;
    //overflow: auto;
  }

  &__title {
    padding: 0 5px;
    //transform: translateY(50%);
    display: inline-block;
    background: $background;
    position: absolute;
    font-size: 24px;
    line-height: 26px;
    left: 15px;
    padding: 0 20px;
    margin-left: -10px;
  }

  .inner {
    padding: 0 20px 20px;
  }

  @media (max-width: 400px) {
    margin-top: 40px;
    &__title {
      font-size: 16px;
      //top: -10px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      line-height: 19px;
    }
    .inner {
      padding: 0 20px 10px;
    }
  }
}
