.select-wrapper {
  position: relative;
  .select {
    z-index: 1;
    &__control {
      border-color: $text;
    }
    &__single-value {
      color: $text !important;
    }
    &__menu {
      border-color: black;
    }

    &__control--is-focused {
      box-shadow: 0 0 0 0.1rem rgba(200, 200, 200, .25);
      border-color: $highlight-text !important;
      border-width: 2px;
    }

    &__value-container {
      font-size: 1rem;
      color: black;
      // color: #495057;
      // color: $header-text;
    }

    &__option {
      color: #495057;
      cursor: pointer;
      &--is-focused {
        background-color: $white;
        color: $header-text;
      }
      &--is-selected {
        background-color: $light-header-bg;
      }
      &:active {
        background-color: $highlight-text;
      }
    }
  }
  .invalid-feedback {
    position: absolute;
    bottom: -22px;
    display: block;
  }

  &.--with-error {
    .select__control {
      border-width: 2px;
      border-color: #dc3545!important;
    }
    .select__control--is-focused {
      box-shadow: 0 0 0 0.1rem rgb(153, 36, 48);
    }
  }
}
