.content-main {
  &__left {
    width: 60%;
    float: left;
  }
  &__right {
    width: 60%;
    float: right;
  }
}

body {

  position: relative;
  height: 100%;
  // background: #303030;
  // background: rbg(205, 205, 205);
  margin: 0px;
}

html {
  height: 100%;
}

.block {
  &__title {
  // background: #303030 !important;
}
}
