.sidebar-right {
  float: right;
  position: absolute;
  width: 40%;
  height: 100%;
  right: 0;

  /* Blue */

  background: #B9D0FF;

  &__image {
    position: absolute;
    right: 0;
    bottom: 0px;
    width: 100%;
    float: right;
  }
  &__logo {
    position: absolute;
    float: right;
    top: 12px;
    right: 12px;
    width: 92px;
    height: 90px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 8px;
    box-sizing: border-box;
  }
}

.sidebar-left {
  float: left;
  position: absolute;
  width: 40%;
  height: 100%;
  left: 0;

  /* Blue */

  background: #B9D0FF;

  &__image {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    float: left;
  }
  &__logo {
    position: absolute;
    float: left;
    top: 12px;
    left: 12px;
    width: 92px;
    height: 90px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 8px;
    box-sizing: border-box;
  }
}








