.tooltip {
  background-color: black;
  //min-width: 120px;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  opacity: 0;
  top: -35px;
  color: $background;
  transition: .2s ease-in-out;
  width: 200px;
  transform: translateX(-50%);
  left: 50%;
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000000 transparent transparent transparent;
    z-index: 2;
  }
}
