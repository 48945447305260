@import "src/modules/applications/pages/Home/blocks/components/ApplicationsBlock/ApplicationsBlock";

.home {
  max-width: 700px;
 .apply-btn {
    margin-top: 30px;
    display: flex;
    font-size: 1.35rem;
    padding: 0.65rem;
  }
}

.dual-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 20rem;
  @media (max-width: 500px) {
    height: 10rem;
  }
}
