.sign_in {
  min-height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    flex: 0 1 550px;
    word-break: break-word;
  }

  &__form {
    max-width: 400px;
    margin: 40px auto 0;
  }

  &__sign_up {
    text-align: center;
    padding: 0 10px;
    margin-top: 20px;
    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__social-services {
    text-align: center;
    margin-top: 20px;
  }

  .invalid-feedback {
    width: max-content;
  }

  @media (max-width: 600px) {
    .invalid-feedback {
      width: fit-content;
      position: relative!important;
      bottom: unset!important;
    }

  }
}
