.input-field {
  position: relative;

  &--with-error {
    input {
      border-width: 2px;
    }
    .invalid-feedback {
      position: absolute;
      bottom: -20px;
    }
  }

  input {
    border-color: $text;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(200, 200, 200, .25);
      border-color: $highlight-text;
      border-width: 2px;
    }
  }
}
