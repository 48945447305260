.container[_ngcontent-ng-c751883576] {
    font-weight: 500;
    border: solid 2px;
    display: f;
    flex-direction: column;
    align-content: center;
    text-align: center;
    margin-top: 50px;
    border-radius: 8px;
    border-color: #a6ce39;
    width: 255px;
}

.container[_ngcontent-ng-c751883576] div[_ngcontent-ng-c751883575] {
    margin-top: -33px;
    height: 50px;
    width: 50px;
    margin-left: auto;
    margin-right: auto;
    background: url(./../../../../../../../common/assets/img/social/ORCIDiD_iconvector.svg) no-repeat;

}
