@import "../LinkWithSubMenu/LinkWithSubmenu";

.header {
  // background-color: rgb(34, 34, 34);
  background-color: $header-bg;
  height: 60px;
  position: fixed;
  top: 0;
  width: 100vw;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .19);
  z-index: 999;

  .inner {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    img {
      height: 7rem;
    }
    @media (max-width: 399px) {
      img {
        height: 3.8rem;
      }
    }
  }

  &__list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin-left: 40px;
    }
  }

  &__link {
    color: $text;
    white-space: nowrap;
    font-size: 20px;
    &:hover {
      text-decoration: none;
      color: $header-text;
    }
  }
}
