.no-found {
  text-align: center;
  margin-top: 50px;
  &__code {
    font-size: 80px;
  }
  &__text {
    font-size: 30px;
  }
}
