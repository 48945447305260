.sign_up {
  min-height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 550px;
    flex-shrink: 1;
    flex-grow: 0;
    word-break: break-word;
  }

  &__form {
    margin-top: 20px;
    flex-basis: 300px;
    flex-shrink: 1;
  }

  &__login {
    padding: 0 10px;
    margin-top: 20px;
  }

  &__text {
    padding: 0 10px
  }

  &__social-services {
    margin-top: 20px;
  }

  @media (min-width: 300px) {
    &__form {
      min-width: 300px;
    }
  }
}
