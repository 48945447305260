html, body {
  height: 100%;
}

body, #root {
  min-height: 100%;
  min-width: 100%;
}

* {
  box-sizing: border-box;
}


body {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background;
  color: $header-text;
  font-weight: 400;
  font-size: 112.5%;
  line-height: 1.56;
}

.blocked:hover {
  .tooltip {
    opacity: .7;
  }
}

.narrow {
  max-width: 640px;
  margin: 0 auto;
}

.error {
  color: $red;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

a {
  color: $highlight-text;
  &:hover {
    color: $highlight-text;
  }
}

.invalid-feedback, .error {
  font-size: 81%;
  font-weight: 600;
}

code {
  font-weight: 900;
  color: $header-text;
}

iframe {
  border-width: 1px;
  border-color: $text;
  border-style: solid;
}

table {
  border-style: solid;
  border-color: $white;
}

.table-wrapper {
  border-style: solid;
  border-radius: 4px;
  border-width: 1px ;
}
