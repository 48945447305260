.password-container {
    // font-family: Arial, sans-serif;
    // font-family: 'Open Sans', sans-serif;
    color: $text;
    max-width: 600px;
    margin: 0 auto;
    padding-top: 20px;
    display: block;
}

.password-form {
  margin-top: 20px;
}

.password-btn {
  margin-top: 10px;
}

p {
  font-size: 1rem;
}
