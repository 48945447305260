.textarea-field {
  position: relative;
  textarea {
    border-color: $text;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(200, 200, 200, .25);
      border-color: $highlight-text;
      border-width: 2px;
    }
  }
  .invalid-feedback {
    position: absolute;
    bottom: -20px;
    display: block;
  }

  &.--with-error {
    textarea {
      border-color: $red;
      border-width: 2px;
      &:focus {
        box-shadow: 0 0 0 0.1rem rgb(153, 36, 48);
      }
    }
  }
}
