.snackbar {
  transition: all .25s ease-in-out;
  opacity: 1;
  min-width: 250px;
  .toast-header {
    background-color: $header-bg;
    color: $text;
  }
  .toast-body {
    opacity: 1;
    // color: #6c757d;
    color: $text;
  }
}
