.create-ta-application-form {
  &__buttons {
    text-align: right;
    button:not(:first-child) {
      margin-left: 10px;
    }
  }
}

.timeslot-error {
  color: $red;
  font-size: 81%;
  font-weight: 600;
  margin-top: -20px;
  margin-bottom: 50px;
}
