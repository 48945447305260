.student-in-pod-info {
  flex: 1 0 110px;
  text-align: center;
  &__img {
    img {
      width: 64px;
      height: 64px;
      object-fit: contain;
    }
  }
  &__link {

  }
}
