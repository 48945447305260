.application-intro {


  &__desc {

  }

  &__text {
    margin-top: 15px;
  }

  &__next-page {
    margin-top: 10px;
    text-align: right;
  }
}
