.course-block {
  border: 1px solid $text;
  position: relative;

  @media (max-width: 500px) {
    .block__title {
      font-size: 1.2rem;
    }
  }

  .inner {
    padding: 0 20px 20px;
  }

  &:not(:first-child) {
    //margin-top: 20px;
  }

  &:first-child {
    //margin-top: 10px;
  }

  &__title {
    padding: 0 5px;
    background: $white;
    position: absolute;
    top: -19px;
    font-size: 24px;
  }

  &__desc {
    margin-top: 40px;
  }
  @media (max-width: 500px) {
    margin: 0;
    &__desc {
      margin-top: 60px;
    }
  }

  &__applications {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    @media (max-width: 430px) {
      flex-direction: column;
      align-items: center;
      .course-block__application-item:not(:first-child) {
        margin-top: 20px;
      }
    }
  }

  &__application-item {
    position: relative;
    flex: 0 1 150px;
    text-align: center;
    color: $text;

    &:hover {
      color: $highlight-text;
      path {
        fill: $highlight-text;
      }
    }

    svg {
      margin: 0 auto;
      display: block;
      width: 64px;
      height: 64px;
      path, circle {
        fill: $text;
      }
    }

    .p-error {
      color: $red;
    }

    .text {
      display: block;
      margin-top: 5px;
      width: 15rem;
    }
  }

  &__application-item.blocked {
    &:hover {
      cursor: unset;
      text-decoration: none;
    }

    svg {
      path, circle {
        fill: $white;
      }
    }

    .text {
      color: $white;
    }
  }
}

@media (max-width: 500px) {
  .applications {
    .course-block__desc {
      margin-top: -10px;
    }
  }
}
