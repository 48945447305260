.waive-fee {
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__textarea-wrapper {
    flex: 1 1 400px;
  }

  &__btns {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    button:last-child {
      margin-top: 10px;
    }
  }

  @media (max-width: 449px) {
    &__footer {
      flex-direction: column;
      align-items: flex-start;
    }

    &__textarea-wrapper {
      width: 100%;
      flex-basis: unset;
    }

    &__btns {
      flex-direction: row;
      margin-top: 10px;
      margin-left: 0;
      button:last-child {
        margin-top: 0;
        margin-left: 10px;
      }
    }
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 650px;
    }
  }
}
