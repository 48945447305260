.button {
  background-color: $text;
  border-color: $text;
  color: $white;
  transition: all .25s ease-in-out;

  &:hover, &:focus {
    background-color: $header-text;
    border-color: $header-text;
    color: $white;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 0, 255, .25);
  }

  &:active {
    background-color: $highlight-text;
  }

  &:disabled {
    transition: all .25s ease-in-out;
  }
}
