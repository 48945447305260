.courses-blocks {
  .inner {
    max-width: 700px;
    margin: 0 auto;
  }
}

.course-block {
  border: 1px solid $text;
  padding: 20px;
}

.course-buttons-case {
  float: left;
  width: 50%;
  padding: 25px;
}

.course-buttons {
  padding: 7px;
  width: 100%;
  height: 65px;
  margin-bottom: 20px;


  &:hover, &:focus {
  background-color: $dark-pink;
  border-color: $dark-pink;
  color: $highlight-text;
  }
}
