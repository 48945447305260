.collapsible {
  margin: 40px 0;
  .content {
    // padding: 6px;
    // background-color: rgb(240, 240, 240);
    font-size: 1rem;
  }
}
.collapsible-header {
    // background-color: rgb(200, 200, 200);
    // padding: 6px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid rgb(200, 200, 200);
    margin-bottom: 15px;

    .title {
      font-size: 1.7rem;
      font-weight: 400;
    }

    .title-sml {
      font-size: 1.2rem;
      font-weight: 400;
    }
    @media (max-width: 500px) {
      .title-sml {
        font-size: 1rem;
    }
  }
}
