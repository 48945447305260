.input-file {
  position: relative;
  input {
    position: absolute;
    top: 0;
    width: 0.1px;
    left: 0;
    bottom: 0;
    outline: none;
    z-index: -1;

  }
  button {
    position: relative;
    pointer-events: none;
  }
}
