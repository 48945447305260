.checkbox {
  position: relative;
  .invalid-feedback {
    position: absolute;
    display: block;
    bottom: -17px;
  }
}

.form-check-label {
  margin-left: 7px;
}
