.table-wrapper {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 700px;
  input[type='radio'] {
    accent-color: $header-text;
  }
}

.table {
  background: #FFFFFF;
  font-size: 14px;
  .invalid-feedback {
    position: absolute;
    bottom: 0;
    display: block;
  }

  th {
    position: relative;
  }

  @media (max-width: 400px) {
    font-size: 12px;
    td, th {
      padding: 0.45rem;
      font-weight: 600;
    }
  }

  @media (max-width: 300px) {
    font-size: 11px;
    td, th {
      padding: 0.25rem;
    }
  }
}
