.snackbar-container {
  position: fixed;
  min-height: 200px;
  top: 75px;
  right: 10px;
  z-index: 1000;
  .inner {
    position: absolute;
    top: 0;
    right: 0;
  }
}
