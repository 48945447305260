$dark-blue-opacity-1: #F2F6FA;
$white: #e0e0e0;
$pink: #bd49d7;
$dark-pink: #9722b1;
$grey: grey;
$black: #222222;
$red: #dc3545;

$background: #FFFFFF;
$header-bg: #ABBAC1;
$light-header-bg: #b3c2c9;
$text: #333333;
$header-text: #000000;
$highlight-text: #FF7676;
