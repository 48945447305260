.forgot-password {
  min-height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    flex-basis: 600px;
    flex-shrink: 1;
    padding: 0 10px;
  }

  &__submit-btn {
    width: 100%;
  }

  &__auth-links {
    display: flex;
    justify-content: space-between;
  }

  &__description {
    //color: $gray-800
  }
}
