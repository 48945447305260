@page {
  size: letter;
  margin: 0;
}

.body-certificate {
  background: #FFF!important;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

}

.certificate {
  text-align: center;
  margin: 20px auto auto;
  overflow-wrap: break-word;
  padding: 0 10px;

  &__header-img {
    max-width: 500px;
  }

  .badges {
    margin-top: 10px;
    display: block;
    justify-content: center;
  }

  .badges div{
    line-height: normal;
    justify-content: center;
    margin: 10px;
  }

  .badges span{
    vertical-align: bottom;
    text-align: left;
    word-wrap: break-word;
    width: 290px;
    display: inline-block;
  }

  .badges p{
    font-size: 9pt;
    vertical-align: bottom;
    justify-content: left;
  }

  .badges img {
    vertical-align: middle;
    margin: 10px 0px 25px;
  }

  h1 {
    color: #333;
    font-family: SnellRoundhand-Black, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 42pt;
  }

  h2 {
    color: #333;
    font-family: Georgia, serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 32pt;
  }

  p {
    color: #333;
    font-family: Helvetica-Light, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 18pt;
  }
  a {
    font-weight: bold;
  }
}


