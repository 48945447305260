.create-mentor-application-form {
  &__buttons {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    button:not(:first-child) {
      margin-left: 10px;
    }
  }
}
