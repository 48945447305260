.social-services {

  &__github {
    border: 1px solid $text !important;
    background: $white;
    &:before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      object-fit: contain;
      background: url(./../../../../common/assets/img/social/GitHub-Mark-64px.png) no-repeat;
      background-size: contain;
      margin-right: 10px;
    }
  }

  &__google {
    border: 1px solid $text !important;
    div {
      display: flex;
    }
  }

  &__google_disconnect {
    border: 1px solid $text !important;
    div {
      display: flex;
    }
    background: white;
    &:before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      object-fit: contain;
      background: url(./../../../../common/assets/img/social/google_logo.svg) no-repeat;
      background-size: contain;
      margin-right: 10px;
    }
  }

  &__orcid {
    border: 1px solid $text !important;
    div {
      display: flex;
    }
    // border: 1px solid $text !important;
    // background: $white;
    &:before {
      content: '';
      display: inline-block;
      width: 22px;
      height: 20px;
      object-fit: contain;
      background: url(./../../../../common/assets/img/social/ORCIDiD_iconvector.svg) no-repeat;
      background-size: contain;
      margin-right: 10px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    text-align: center;
  }

  button {
    height: 30px;
    font-size: 15px;
    border: none;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 10px;
    font-family: inherit!important;
    // this is because of lib styling
    // maybe rework with classes
    div {
      padding: 0!important;
    }
  }

  svg {
    path, circle {
      //fill: ;
    }
  }
}
