.logistics {
  .inner {
    margin: 0 auto;
    padding: 30px 0 0;
    max-width: 700px;
    margin-top: -30px;
    @media (max-width: 550px) {
      margin-top: -45px;
    }
  }

  .edit-logistics {
    &__title {
      font-size: 24px;
    }

    &__inputs {
      margin-right: 10px;
      flex: 0 1 600px;
      @media (max-width: 550px) {
        margin-right: 0;
      }
    }

    &__form {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      @media (max-width: 550px) {
        display: block;
      }
    }

    &__photo {
      flex: 0 1 300px;
      text-align: center;
      img {
        object-fit: contain;
        height: auto;
        width: 100%;
        max-height: 300px;
      }
    }
  }

  &__social-services {
    display: flex;
  }
}
